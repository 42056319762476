export const components = {
  achievement: {
    added_by: 'Source:',
    authors: 'Authors',
    availability: 'Availability:',
    conference_material: 'Conference material',
    created_at: 'Created at',
    databases: 'Databases:',
    discipline: 'Discipline:',
    duplicate: 'Duplicate',
    evaluation: 'Evaluation',
    monograph: 'Monograph',
    not_submited: 'Not submitted for evaluation',
    register: 'Register',
    submited: 'Submitted for evaluation',
    updated_at: 'Updated at'
  },
  dn_discipline_statements: {
    discipline: 'Discipline',
    discipline_participation: 'Percentage share of the discipline',
    dissertation_from: 'Doctoral school from',
    dissertation_to: 'Doctoral school to',
    exact_date: 'Exact date',
    field: 'Field of study',
    is_leading: 'Primary discipline',
    overwriting_statements: 'Overwriting statements',
    overwriting_statements_confirmation: 'Adding this statement will overwrite the previous statement. Are you sure you want to proceed?',
    validity_from: 'Validity from',
    validity_to: 'Validity to'
  },
  dn_discipline_user: {
    discipline_user: 'Discipline worker',
    edit_user: 'Edit discipline workers',
    employee: 'Discipline staff'
  },
  dn_dissertation: {
    application: 'N number submission',
    change: 'change',
    changed: 'Changed',
    choose: 'choose',
    confirm: 'confirm',
    choose_discipline: 'Choose discipline',
    choose_disciplines: 'Choose disciplines',
    deadline: ' | You have 1 month to submit your work | You have {count} months to submit your work',
    discipline_selection: 'Discipline selection',
    edit_n: 'edit N',
    evaluation: 'Submission for evaluation',
    fill_statement: 'No statement',
    header: 'Doctoral school',
    header_tooltip: 'The discipline of the doctoral dissertation and years of attendance to the doctoral school',
    no_discipline: 'No discipline selected',
    remove: 'remove',
    remove_dissertation: 'Removing',
    remove_dissertation_confirmation: 'Are you sure you want to remove the attendance of the doctoral school?'
  },
  dn_employments: {
    absence: 'Vacation over two years',
    change: 'change',
    confirmed: 'Confirmed',
    dissertation: 'Doctoral dissertation',
    dissertation_tooltip: 'Student of a doctoral school who, during the evaluation, was preparing a doctoral dissertation in the discipline or in the field of study of this discipline',
    doctorant_in_school: 'Doctoral student',
    edit_employments: 'To be able to edit an employment you must first confirm the employment',
    employment_set: 'Employment confirmed',
    evaluation_years: 'Evaluation years',
    from: 'From:',
    full_working_time: 'set a full working time',
    header: 'Working time',
    insufficient_time: 'Employed under one year',
    insufficient_time_current_tooltip: 'If an employee was employed for less than 12 months and the employment ended before December 31 of the evaluated year, this employee will not be included in N.',
    insufficient_time_tooltip: 'An employee employed continuously for less than 12 months during the evaluation, with scientific responsibilities. Applies to employee not included in the number N in the evaluation.',
    ignore_minuses: 'Exemption from restrictions',
    ignore_minuses_tooltip: 'In the evaluated period, the employee:\n1) had unpaid leave,\n2) had a leave for health reasons,\n3) had any of the leaves related to parenthood, specified in the Act of June 26, 1974 - Labor Code (Journal of Laws of 2018, item 917, as amended),\n4) had paid sickness leave or had paid rehabilitation leave, Act of 25 June 1999 on cash benefits from social insurance in the event of sickness and maternity ( Journal of Laws of 2017, item 1368, as amended)\n\n- for a total of at least 24 months or for at least half of the employment in the evaluated entity.\n\n5) in the period covered by the evaluation was employed in the entity in a position responsible for conducting scientific activity, less than 24 months (36 months in the case of the 2022 evaluation) \n6) had a statement for disciplines for evaluated period shorter than 24 months (or 36 months in 2022 evaluation)',
    modify: 'Modify',
    months: '0 months | 1 month | {count} months',
    not_enough: 'Minus points exception',
    not_enough_text: 'An employee during the evaluation period was employed under 2 years (3 years in the case of evaluation 2021) or spent over 2 years on vacation and leave.',
    no_required_working_time: 'No required working time',
    other_statement: 'N in another entity',
    other_statement_tooltip: 'In the evaluated period, the employee submitted a declaration of being classified as N in another entity.',
    position: 'Position',
    reset_working_time: 'reset working time',
    to: 'To:',
    vacation: 'Vacation',
    working_time: 'Working time',
    year: 'Year'
  },
  dn_evaluation: {
    and: 'and',
    article: 'Article',
    authors: '0 authors | 1 author | {count} authors',
    choose_disciplines: 'Choose disciplines',
    choose_doctoral_school: 'Choose period and discipline',
    conference: 'Conference',
    count_slots: 'Slots:',
    creators: '0 creators | 1 creator | {count} creators',
    empty_discipline: 'No discipline statement',
    empty_doctoral_school: 'No doctoral school',
    empty_publications: 'Works will appear here',
    estimated_result: 'Estimated result',
    estimated_result_text: 'Estimation of the result when 100% of the slots are filled. Based on the current point score and the slots fullfilment',
    estimated_result_text_artistic: 'Estimation of the result when 100% of the slots are filled. Based on the current point score and the limit fullfilment',
    filling_limits: 'filling\nlimit',
    filling_percent: 'fill',
    filling_slots: 'filling\nslots',
    for_discipline: 'for discipline',
    from: 'from',
    header: 'Current evaluation',
    hide_others: 'Hide others',
    included_slots: '0 slots will be included in the evaluation | {count} slot will be included in the evaluation | {count} slots will be included in the evaluation',
    monograph: 'Monograph',
    no_achievements: 'No works',
    not_assigned_publications: ' | {count} Work not assigned to the disciplines | {count} Works not assigned to the disciplines',
    not_evaluated: 'Discipline is not subject to evaluation',
    no_publications: 'No achievements in this discipline',
    not_submitted_for_evaluation: ' | and 1 not submitted for evaluation | and {count} not submitted for evaluation',
    patent: 'Patent',
    patents: 'Patents',
    periodical: 'Journal:',
    points: 'points | point | points',
    publications: 'Works',
    publisher: 'Publisher:',
    position_points: 'Position against the highest score in disciplin',
    score: 'Score',
    scoring: 'Scoring',
    scoring_upper_percent: 'Your score places you in the top {count}% of our university\'s academic staff',
    set_discipline: 'Set primary discipline from statement',
    set_discipline_title: 'Set primary discipline.',
    set_discipline_confirmation: 'You want to assign the primary discipline to all works without discipline.',
    show_all: 'Evidence of works',
    show_others: 'Show others',
    slot_text: 'Slots',
    slots: 'slots | slot | slots',
    slots_tooltip: 'Maximum amount of shares in works that are possible to evaluate in the selected discipline.\n' +
      '\n' +
      'The limit is calculated by multipling working time (based on data from 31th December each evaluated year) and the share of working time associated with scientific activity in the selected discipline declared in a statement.',
    slots_upper_percent: 'Filled slots places you in the top {count}% of our university\'s research staff',
    start_date: 'Start date:',
    with: 'with',
    working_time: 'Slot:',
    year: 'Year',
    years: 'years | year | years',
    your_empty_publications: 'Your works will appear here',
    your_estimated_result: 'Your estimated result',
    your_future_estimations: 'Your estimates will appear here',
    your_patents: 'Your patents',
    your_publications: 'Your works',
    your_score: 'Your score'
  },
  dn_field: {
    add: '+ Add',
    applications: 'Number N ({from}-{to} average)',
    change: 'change',
    choose: 'choose',
    chosen_discipline: 'Selected discipline',
    discipline_manager: 'Discipline manager',
    economic: 'Reference unit: Faculty of Economic Sciences (HS1EK), scientific category: B',
    economic_text: 'Criterion reference value A: 150 points, elevation point G: 0.2',
    edit_manager: 'Edit managers',
    filling_limits: 'Filling limit',
    filling_slots: 'Filling slots',
    it: 'Reference unit: Faculty of Electronics and Computer Science (SI1EA), scientific category: B',
    it_text: 'Criterion reference value A: 120 points, elevation point G: 0.2',
    job_positions: 'Counted tenures',
    manager: 'Head of discipline:',
    no_manager: 'Not selected',
    not_enough_job_positions: 'Not enough applications for number N',
    not_enough_job_positions_tooltip: 'Pursuant to Article 265, section 4 of the Act of July 20, 2018, Law on Higher Education and Science. The number N is calculated as of December 31 of the last year of a given evaluation period.',
    number_n_last_year: 'Number N ({year}):',
    number_of_publications: 'Works included:',
    people: '0 people | 1 person | {count} people',
    points: 'points | point | points',
    points_n: 'Points on N',
    too_many_job_positions: 'More reported tenures than needed in the application for the discipline'
  },
  dn_file_input: {
    error_title: 'An error occured',
    loading_title: 'Loading file',
    success_title: 'File loaded successfully',
    upload_title: 'Drag and drop or select a file'
  },
  dn_footer: {
    achievement: 'The achievements of',
    achievements: 'scientific works',
    integration: 'Integration with databases',
    periodical_count: '30 thousands',
    periodicals: 'journals including the ministerial list',
    million: '1 million',
    orcid: 'ORCID and PBN',
    researchers: 'researches',
    thousands: '100 thousands'
  },
  dn_header_navbar: {
    login_btn: 'Login',
    register_btn: 'Register'
  },
  dn_layout_footer: {
    accessibility_declaration: 'Accessibility statement'
  },
  dn_main_field: {
    disciplines_count: '0 disciplines | 1 discipline | {count} disciplines',
    evaluated: 'To evaluation',
    not_evaluated: 'Not to evaluation',
    job_positions_count: '0 jobs | 1 job | {count} jobs'
  },
  dn_navigation_menu: {
    academy_reports: 'Academy reports',
    base_blocks: 'Blocks',
    components: 'Components',
    contact: 'Contact',
    dashboard: 'Dashboard',
    dictionaries: 'Dictionaries',
    log_in: 'Login',
    logout: 'Log out',
    static_pages: 'Static pages',
    disciplines: 'Disciplines',
    discipline_selection: 'Disciplins selection',
    duplicate_reports: 'Duplicate report',
    my_statements: 'My statements',
    achievements: 'Works',
    my_achievements: 'My achievements',
    new_achievement: 'New work',
    evidence: 'Evidence',
    statements: 'Statements',
    employees: 'Employees',
    list_of_employees: 'List of employees',
    new_employee: 'New employee',
    history: 'History',
    instances: 'Instances',
    faculties: 'Units',
    academies: 'Academies',
    evaluations: 'Evaluations',
    my_evaluation: 'My evaluation',
    import_eksport: 'Import/Export',
    import_orcid: 'Import ORCID',
    import_pbn_xml: 'Import PBN XML',
    import_employees: 'Import employees',
    import_statements_of_publications: 'Import statements about publications',
    import_art_achievements: 'Import artistic achievements',
    export_pbn_xml: 'Export PBN XML',
    integration_pbn: 'Integration PBN',
    import_pbn_api: 'Import PBN API',
    import_pbn_dictionaries: 'Import dictionaries from PBN',
    import_polon_dictionaries: 'Import dictionaries from POL-on',
    import_publications_and_employees: 'Import publications and employees',
    orcid_reports: 'Export ORCID reports',
    reports: 'Reports',
    import_polon: 'Import of POLon achievements',
    import_polon_employees: 'POLon employee data import',
    publishers: 'Publishers',
    periodicals: 'Journals',
    conferences: 'Conferences',
    kind_of_arts_achievements: 'Type of art work',
    financing_institutions: 'Financing institutions',
    entities_realizing_projects: 'Entities Implementing Projects',
    financing_sources: 'Financing sources',
    rights: 'Permissions',
    users: 'Users'
  },
  abk_navigation_menu: {
    dictionaries: 'Dictionaries',
    dictionaries_of_consents: 'Dictionaries of consents'
  },
  dn_orcid: {
    achievements: 'See your works in ORCID',
    change: 'change',
    change_orcid: 'Changing ORCID ID',
    change_orcid_confirmation: 'Works imported so far from ORCID will be erased.',
    edit_orcid: 'Edit ORCID',
    header: 'Profile',
    link_account: 'Link an account',
    my_header: 'My profile',
    no_orcid: 'No ORCID number provided',
    synchronize: 'synchronize'
  },
  dn_polon: {
    add_id: 'Add ministerial identifiers',
    change: 'change',
    edit_id: 'Edit ministerial identifiers',
    no_id: 'Not reported',
    no_polon: 'No identifiers were given',
    polon_id: 'POL-on ID identifier',
    polon_uuid: 'POL-on UUID identifier',
    pbn_id: 'PBN ID identifier'
  },
  dn_orcid_integration: {
    archives: 'Archives',
    days: '0 days | 1 day | {count} days',
    information: 'On this screen you can import new works from the ORCID system',
    last_synchronization: 'Last synchronization:',
    monograph_info: 'Authorship and editing of monographs',
    none: 'none',
    number_of_articles: 'Articles:',
    number_of_artistic: 'Artistic activity:',
    number_of_chapters: 'Chapters:',
    number_of_monographs: 'Monographs:',
    number_of_monography_and_redactions: 'Monographs with redaction:',
    number_of_patents: 'Patents:',
    number_of_projects: 'Projects:',
    number_of_publications: 'Works:',
    number_of_redactions: 'Monograph editions:',
    number_of_services: 'Research services:',
    number_of_socials: 'Impact on the environment:',
    see_list: 'see list',
    synchronized_positions: 'Downloaded works:'
  },
  dn_previous_statement: {
    header: 'Selected disciplines',
    publications: '0 works | 1 work | {count} works'
  },
  dn_publication_statistics: {
    articles: 'Articles',
    artistic: 'Artistic',
    chapters: 'Chapters',
    header: 'Works\' statistics',
    monographs: 'Monographs',
    monograhpy_redactions: 'Monographs with redactions',
    others: 'Others',
    patents: 'Patents',
    redactions: 'Monograph editions',
    show_all: 'Show all works',
    summary: 'Summary',
    tooltip: 'Statistics of all works with points.',
    total_publications: 'All'
  },
  dn_statements: {
    active: 'Active',
    application: 'N number submission',
    change: 'change',
    changed: 'Changed',
    choose: 'choose',
    confirm: 'confirm',
    choose_discipline: 'Choose discipline',
    choose_disciplines: 'Choose disciplines',
    deadline: ' | You have 1 month to submit your work | You have {count} months to submit your work',
    discipline_selection: 'Discipline selection',
    edit: 'edit',
    edit_n: 'edit N',
    edit_statement_end_date: 'Editing the end date of the statement',
    edit_statement_end_date_confirmation: 'Modifying the end date of the discipline\'s statement will change the end date of the second discipline statement, if submitted. You may also be required to change the end date of the declaration to N.',
    evaluation: 'Application for evaluation',
    fill_statement: 'No statement',
    header: 'Statements',
    no_discipline: 'No discipline selected',
    number_n: 'Application for the number N',
    number_n_confirmation: 'Do you want to submit discipline to number N?',
    number_n_error: 'The period of application to N is not included in the period of validity of the declaration of discipline',
    number_n_success: 'The number N has been declared successfully',
    remove: 'remove',
    select_active: 'or set active',
    select_a_date: 'Select date'
  },
  pcg_header_profile_button: {
    change_evaluation_period: 'Change the active evaluation period',
    changing_evaluation_period: 'Changing the active evaluation period',
    gen_local_token: 'Make token',
    local_token: 'Local token',
    lang_change_to: 'Change language',
    logout: 'Logout',
    role_change_to: 'Change role to {role}'
  },
  pcg_pagination_summary: {
    summary: '{from} - {to} of {total}'
  },
  pcg_search_select: {
    add_value: 'Add value'
  },
  pcg_options: {
    edit: 'Edit',
    preview: 'Preview',
    comments: 'Comments',
    publication_history: 'History',
    archive: 'Archive',
    restore: 'Restore'
  },
  polon_element: {
    delete: 'Delete',
    import: 'Import'
  },
  publication_element: {
    achievement_of_great_importance: 'Importance: work of great importance',
    achievement_of_minor_importance: 'Importance: work of minor importance',
    achievement_other_cases: 'Importance: other cases',
    actions: 'Actions',
    add_alternative_points: 'Add corrected points',
    alternative_points: 'Corrected points',
    archiving: 'Archiving',
    archiving_confirmation: 'Are you sure you want to archive this work?',
    check_original: 'Check original for:',
    comments: 'Comments',
    comments_error: 'You do not have permission to edit other users\' comments',
    conference: 'conference',
    conference_search: 'Enter a conference name',
    default_status_pbn: 'Select the default POL-on / PBN status',
    discipline: 'Discipline',
    disciplines: 'Disciplines',
    duplicate_search: 'Find a work that duplicates the TITLE',
    edit_alternative_points: 'Edit or delete added manually points',
    edit_conference_series: 'Edit conference series',
    export_orcid: 'Export of achievement to ORCID',
    export_orcid_token: 'Provide token and ORCID',
    no_affiliation: '1. No affiliation',
    no_discipline: '3. No statements at all',
    no_employment: '5. Publication prior to year of employment',
    no_ndiscipline: '2. There is no number N',
    orcid_token: 'Token',
    orcid_user_id: 'ORCID',
    outdated_statement: '4. Outdated statements',
    outstanding_achievement: 'Importance: outstanding work',
    periodical: 'journal',
    periodical_publisher: 'journal publisher',
    periodical_search: 'Enter the title of the journal or its ISSN according to Wos or SCOPUS.',
    points: 'Points',
    project_manager: 'Managers of disciplines',
    publisher: 'publisher',
    publisher_search: 'Enter a publisher\'s name',
    restoring: 'Restoring',
    restoring_confirmation: 'Are you sure you want to restore work?',
    show_duplicates: 'Show duplicates',
    statuses: {
      accepted: 'Accepted',
      all: 'All',
      archive: 'Archives',
      draft: 'Draft',
      duplicate: 'Duplicate',
      for_approval: 'For approval',
      status: 'Status',
      improve: 'To improve',
      verified: 'Verified'
    },
    statusesPolon: {
      all: 'All',
      do_not_send: 'Do not send',
      found: 'Sent/Found in the repository',
      start: 'Choose',
      to_send: 'To send',
      send_error: 'Error when sending',
      to_resend: 'For re-send',
      sent: 'Sent',
      sent_manually: 'Sent manually',
      sent_partially: 'Sent/Incomplete',
      status_pbn: 'POL-on/PBN status'
    },
    statusesPbn: {
      all: 'All',
      do_not_send: 'Do not send',
      found: 'Sent/Found in the repository',
      start: 'Choose',
      to_send: 'For shipment',
      send_error: 'Shipping error',
      to_resend: 'For re-shipment',
      sent_manually: 'Sent manually',
      sent_partially: 'Sent/Incomplete',
      sent: 'Shipped',
      status_pbn: 'POL-on/PBN status'
    },
    type: 'Work type',
    wrong_points: 'Failed to add points, incorrect format'
  },
  publication_table_element: {
    abstract: 'Project abstract',
    achievement_source: 'Data source',
    achievement_status: 'Status',
    achievement_status_pbn: 'POL-on/PBN shipping status',
    achievement_title: 'Work title',
    achievement_title_en: 'Other language work title',
    achievement_type: 'Type',
    achievement_year: 'Year',
    actions: 'Actions',
    another_contest_name: 'Another contest name',
    citations: 'EN Liczba cytowań źródła',
    cite_score: 'EN CiteScore',
    cited: 'EN Procent cytowań',
    collective_actions: 'Collective actions',
    conference: 'Conference',
    contest_name: 'Contest name',
    contract_date: 'Contract date',
    contributor_discipline: 'Co-author discipline',
    contributor_disciplines_share: 'Percentage share of the discipline of co-author',
    contributor_doctoral_school: 'Co-author\'s doctoral school code',
    contributor_evaluation: 'Consent for evaluation of co-author',
    contributor_faculty: 'Assigned co-author faculties',
    contributor_name_and_id: 'Co-author data',
    contributor_part: 'Percentage share of co-author',
    contributor_points: 'Co-author points',
    contributors: 'Co-authors',
    country_amount: 'Country amount',
    created_at: 'Created at',
    discipline: 'Discipline',
    documents: 'Number of documents',
    doi: 'DOI',
    duplicates: 'Duplicates in the system',
    duplicate_id: 'Identifier:',
    entities_realizing_projects: 'Entities realizing projects',
    export_error: 'Export error',
    external_id: 'External identifier',
    external_link: 'Website',
    fee_amount: 'Fee amount',
    fee_type: 'Fee type',
    financing_source: 'Financing source',
    financing_institution_different: 'Different financing institution',
    financing_institution_different_name: 'Financing institution name',
    flags: 'Flags',
    foreign_amount: 'Foreign amount',
    foreign_source: 'Foreign source',
    highest_percentile: 'EN Najwyższy percentyl',
    isbn: 'ISBN',
    issn: 'ISSN',
    keywords: 'Keywords',
    ministerial_list: 'Ministerial list',
    no_discipline: 'No discipline',
    no_doctoral_school_code: 'No code',
    no_doctoral_school_code_required: 'Not applicable',
    no_evaluation: 'No consent to the evaluation',
    no_faculties: 'No assigned faculties',
    no_points: 'No points',
    periodical: 'Journal',
    principal_name: 'Principal name',
    project_abstract_en: 'Project abstract in english',
    project_acronym: 'Project acronym',
    project_contest_edition: 'Contest edition',
    project_end: 'Project end',
    project_individual: 'Individual project',
    project_leader: 'Partner/Leader',
    project_number: 'Project number',
    project_manager: 'Manager',
    project_participants: 'Project participants',
    project_priority_name: 'Project priority name',
    project_title_en: 'English title of the project',
    project_start: 'Project start',
    project_types: 'Project types',
    publisher: 'Publisher',
    related_projects: 'Related projects',
    report_count: 'Number of achievements',
    report_name: 'Name',
    sjr: 'SJR',
    snip: 'SNIP',
    source_points: 'Source points',
    total_amount: 'Total amount/Income amount',
    updated_at: 'Updated at'
  }
}
